<template>
  <v-container class="pa-0">
    <v-row
      no-gutters
      class="ma-0 pa-0"
    >
      <!-- 모바일 화면 에서는 상단 인포가 안보이게 처리 -->
      <v-col
        cols="12"
        class="d-none d-sm-flex"
      >
        <v-alert
          border="bottom"
          color="info"
          outlined
          dense
          class="ma-0 "
        >
          <div class="text-body-2">
            <v-icon small color="info">info</v-icon>
            카테고리의 삭제는 <span class="error--text font-weight-bold">일괄합체</span>를 활용해 주십시오.
          </div>
          <div class="text-body-2">
            <v-icon small color="info">info</v-icon>
            수정시에는 해당 카테고리와 <span class="primary--text font-weight-bold">연계된 상위 정보</span>도 <span class="error--text font-weight-bold">동반수정</span>됩니다.
          </div>
          <div class="text-body-2">
            <v-icon small color="info">info</v-icon>
            순서의 변경은 해당 카테고리를 잡아서 이동시킨 후 <span class="primary--text font-weight-bold">순서변경</span> 버튼을 클릭하셔야 반영됩니다.
          </div>
        </v-alert>
      </v-col>

      <v-col cols="12" class="pt-0 pb-3 px-3">
        <v-toolbar dense flat class="pa-0 ma-0">
          <v-btn :disabled="!orderChanged" text color="primary" @click="orderChange" class="pa-1 mr-2">
            <v-icon small left class="mr-0">mdi-cached</v-icon>순서변경
          </v-btn>
          <v-btn text :color="allChecked ? 'warning' : 'success'" @click="allCheck" class="pa-1 mr-2">
            <v-icon small left class="mr-0">
              {{ allChecked ? 'mdi-checkbox-multiple-blank-outline' : 'mdi-check-box-multiple-outline' }}
            </v-icon> {{ allChecked ? '전체해제' : '전체선택' }}
          </v-btn>
          <v-btn  text color="indigo" @click="mergeCate" class="pa-1">
            <v-icon small left class="mr-0">mdi-collapse-all</v-icon> 일괄합체
          </v-btn>
          <!-- <v-btn text color="error" @click="allRemove" class="pa-1 mr-2">
            <v-icon small left class="mr-0">mdi-delete-forever-outline</v-icon> 일괄삭제
          </v-btn> -->
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-divider></v-divider>

        <div class="ma-3"></div>

        <draggable
          :list="cates"
          :disabled="!dndEnabled"
          @start="dragging = true"
          @end="dragging = false"
          @update="orderChanged = true"
        >
          <v-row
            v-for="(cate, i) in cates"
            :key="cate.id"
          >
            <v-hover v-slot:default="{ hover }" transition="fade-transition">
              <v-col cols="6" class="my-2 ml-1 mr-1 pa-0">
                <v-chip
                  v-if="!cate.isEdit"
                  :color="(hover || cate.isChecked) ? 'light-blue lighten-5' : 'white'"
                  class="ml-1"
                >
                  <v-icon
                    v-show="!cate.isImmutable"
                    small
                    left
                    class="mr-2"
                    @click="cate.isChecked = !cate.isChecked"
                  >{{ cate.isChecked ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}</v-icon>
                  {{ cate.gubun1 }}
                  <v-icon
                    v-show="hover && !cate.isImmutable"
                    small
                    right
                    @click="setEdit(cate, i)"
                    class="ml-5"
                  >edit</v-icon>
                  <!-- <v-icon
                    v-show="hover && !cate.isImmutable"
                    small
                    right
                    @click="remove(cate, i)"
                    class="ml-3"
                  >mdi-trash-can-outline</v-icon> -->
                </v-chip>
                <v-text-field
                  v-else
                  v-model.trim="edit.gubun1"
                  :rules="[rules.required, rules.counter, rules.chkObverlab]"
                  label=""
                  placeholder="입력하세요(최대 4자)"
                  counter="4"
                  maxlength="4"
                  append-icon="mdi-close-circle-outline"
                  @click:append="cate.isEdit = false; dndEnabled = true"
                  @keydown.esc="cate.isEdit = false; dndEnabled = true"
                  append-outer-icon="done"
                  @click:append-outer="editCate(cate, i)"
                  @keypress.enter="editCate(cate, i)"
                  @keypress="rmSpKeys"
                  @keyup="edit.gubun1 = rmSpChars(edit.gubun1)"
                ></v-text-field>
              </v-col>
            </v-hover>
          </v-row>
        </draggable>
      </v-col>

      <v-col cols="12" class="pl-4">
        <v-row>
          <v-col cols="6" id="inpArea">
            <v-text-field
              ref="txtAddCate"
              v-model.trim="form.gubun1"
              :rules="[rules.required, rules.counter, rules.chkObverlab]"
              label="등록"
              placeholder="입력하세요(최대 4자)"
              counter="4"
              maxlength="4"
              append-icon="mdi-close-circle-outline"
              @click:append="init"
              @keydown.esc="init"
              append-outer-icon="done"
              @click:append-outer="agree()"
              @keypress.enter="agree()"
              @keypress="rmSpKeys"
              @keyup="form.gubun1 = rmSpChars(form.gubun1)"
              @blur="$refs.txtAddCate.resetValidation()"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <merge-cate-dialog ref="mergeCateDialog"></merge-cate-dialog>

  </v-container>
</template>

<script>
import draggable from 'vuedraggable'
import { rmSpKeys, rmSpChars } from '@/lib/keyEvents'
import mergeCateDialog from '@/components/admin/MergeCateDialog'

export default {
  components: {
    draggable,
    mergeCateDialog
  },

  data: () => ({
    typeNum: 22, // !! 타입번호(업무관리상태)
    cates: [],
    selectedCates: [], // !! 선택한 카테고리
    // 등록폼
    form: {
      id: 0,
      num: 0, // 정렬구분자
      type1: 0,
      gubun1: '',
      isEdit: false,
      isChecked: false
    },
    // 수정폼
    edit: {
      id: 0,
      num: 0, // 정렬구분자
      type1: 0,
      gubun1: '',
      isEdit: false,
      isChecked: false
    },
    oldIndex: 0, // 기존 선택한 카테고리
    dndEnabled: true, // dnd 사용여부
    dragging: false,
    allChecked: false,
    orderChanged: false // 순서변경여부
  }),

  computed: {
    // data 에서는 this를 쓸 수 없으므로 computed 에서 룰을 만든다
    rules () {
      return {
        required: value => !!value || '입력값은 필수 정보입니다',
        counter: value => value.length <= 4 || '입력값은 최대 4글자이어야 합니다',
        chkObverlab: value => {
          const gubuns = this.cates.map(s => s.gubun1)
          if (gubuns.includes(value)) {
            return '이미 등록된 카테고리입니다'
          } else {
            return true
          }
        }
      }
    }
  },

  mounted () {
    if (!this.$store.state.ui.dbcode) {
      this.redirect('/')
    } else if (!(this.$store.state.ui.lv === 'M' || this.$store.state.ui.lv === 'G')) {
      // 참고: router.js 에서 이미 체크했지만.. 관리(대행)자가 아니면 .. 기본화면으로 돌려보낸다
      this.redirect('/case')
    }

    // 리스트 데이터 패칭
    this.getDataFromApi()
      .then(({ items }) => {
        if (items && items.length > 0) { // 데이터가 있다면..
          this.cates = items // 데이터를 상위카테고리에 매칭 시키고
        } else {
          this.cates = []
        }
        this.goToInput() // 스크롤을 내린다
      })

    // 폼 초기화
    this.init()
  },

  methods: {
    // 중요: 공통함수 --
    rmSpKeys, // keypress 특수문자 입력 막기
    rmSpChars, // keyup, down 특수문자 제거
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    redirect (to = '') {
      this.$router.push(to)
    },
    // 초기화 함수
    init () {
      // 등록폼 validation 초기화
      this.$refs.txtAddCate.resetValidation()

      // 폼 초기화
      this.form.id = 0
      this.form.num = 0
      this.form.type1 = this.typeNum // 타입
      this.form.gubun1 = ''
      this.form.isEdit = false
      this.form.isChecked = false

      // 수정폼 초기화
      this.edit.id = 0
      this.edit.num = 0
      this.edit.type1 = this.typeNum // 타입
      this.edit.gubun1 = ''
      this.edit.isEdit = false
      this.edit.isChecked = false

      this.oldIndex = 0 // 기존 선택요소를 초기화

      this.dndEnabled = true // 드래그 앤 드랍 사용가능하게
      this.orderChanged = false // 순서변경여부 초기화

      this.selectedCates = [] // !! 선택 카테고리 초기화
    },
    // 리스트 리프레시 함수
    async getList () {
      try {
        const { items } = await this.getDataFromApi()
        // 데이터가 있다면...
        if (items && items.length > 0) {
          // 데이터를 상위카테고리에 매칭 시키고
          this.cates = items
          return items.length
        } else {
          this.cates = []
          return 0
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 데이터 패칭
    async getDataFromApi () {
      try {
        // DB에서 리스트 가져오기 - typeNum 에 해당하는 넘을 가져와야 한다!
        const { data } = await this.$axios.get(`admin/cate/getType/${this.typeNum}`)
        if (!data.success) throw new Error(`list error: ${data.message}`)
        const items = data.cates
        // 업무유형의 '업무','상담','약속','이메일발송'은 편집,삭제,일괄선택이 모두 불가능하다!
        // isImmutable = true 로 불변성으로 고정하자
        // !! [2019.12.26수정] isEver 필드가 1 인 불변필드를 지정했다!
        for (let i = 0; i < items.length; i++) {
          if (items[i].isEver) {
            items[i].isImmutable = true
          } else {
            items[i].isImmutable = false
          }
        }

        return { items }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 참고: 등록 버튼
    async agree () {
      try {
        // 최종 입력값 검증
        if (!this.$refs.txtAddCate.validate() || !this.form.gubun1) {
          // this.$refs.txtAddCate.error = true
          throw new Error('입력값을 확인해 주세요')
        }

        // 중복체크
        if (!this.chkObverlab(this.form.gubun1)) {
          // this.$refs.txtAddCate.error = true
          throw new Error('이미 등록된 카테고리입니다')
        }

        // num 의 가장 큰값을 만들어준다.
        let maxNum = 0
        if (this.cates.length > 0) {
          maxNum = Math.max(...(this.cates.map(s => s.num)))
        }
        this.form.num = maxNum + 1
        this.form.type1 = this.typeNum // !! 타입을 다시 맞춰주는게 중요!

        // DB 처리
        const { data } = await this.$axios.post('admin/cate/addCate', this.form)
        if (!data.success) throw new Error(`오류가 발생하였습니다.: ${data.message}`)
        // refresh
        const dataLength = await this.getList()
        if (dataLength > 0) {
          this.init() // 초기화 하고
          this.goToInput() // 등록폼으로 스크롤을 내린다.
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // edit 선택
    async setEdit (cate, index) {
      if (this.edit.gubun1) { // 기존 선택한게 있으면 초기화
        this.cates[this.oldIndex].isEdit = false
      }
      this.oldIndex = index
      cate.isEdit = true
      this.edit.gubun1 = cate.gubun1
      this.edit.id = cate.id
      this.edit.num = cate.num
      this.edit.isEdit = true // !! 편집중으로 표시
      this.edit.isChecked = cate.isChecked

      this.dndEnabled = false // 선택중엔 드래그앤 드랍 금지
    },
    // 선택편집
    async editCate (cate, index) {
      try {
        // 입력값 비어있는지 체크
        if (!this.edit.gubun1) throw new Error('입력값을 확인해 주세요')
        // 중복체크
        if (!this.chkObverlab(this.edit.gubun1)) throw new Error('이미 등록된 카테고리입니다')

        // !! 편집하는 카테고리의 num,sub 을 edit 폼과 맞춰야 한다!
        this.edit.num = cate.num
        this.edit.sub = cate.sub
        // DB 처리
        const { data } = await this.$axios.post('admin/cate/cateUpdate', this.edit)
        if (!data.success) throw new Error(`오류가 발생하였습니다.: ${data.message}`)
        // refresh
        await this.getList()
        this.init() // 초기화 하고
        cate.isEdit = false // 편집창 닫고
        this.dndEnabled = true // 편집 끝나면 다시 드래그 가능하게 한다
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 중복체크 함수
    chkObverlab (value) {
      if (this.cates || this.cates.length > 0) {
        const gubuns = this.cates.map(s => s.gubun1)
        if (gubuns.includes(value)) {
          return false
        } else {
          return true
        }
      }
    },
    // !! 리스트 순서를 변경하도록 하는 메서드
    async orderChange (e) {
      try {
        if (this.orderChanged) { // 순서가 변경된 경우만..
          this.orderChanged = false // 순서변경 여부 초기화

          // !![2021.3.8] 변경전 num 과 새로운 num 을 각각 패칭한다.
          let changes = []
          this.cates.forEach((item, index) => {
            const oldNum = item.num
            const newNum = index + 1
            const caption = item.gubun1
            if (oldNum !== newNum) { // !! 변경이 있는 것만 작업한다.
              changes.push({ id: item.id, oldNum, newNum, caption })
            }
          })
          if (changes.length) {
            // DB 처리 - [2021.3.8] 백앤드 changeCateNum() 로 변경함
            const { data } = await this.$axios.post('admin/cate/changeCateNum', { type1: this.typeNum, changes })
            if (!data.success) throw new Error(`오류가 발생하였습니다.: ${data.message}`)
            await this.getList() // refresh
            this.init() // 초기화
          }
        } else {
          throw new Error('카테고리 순서를 먼저 변경해 주세요')
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 리스트 체크박스 전체 선택/해제
    allCheck () {
      if (this.cates.length > 0) {
        if (!this.allChecked) {
          this.cates.forEach(c => {
            if (!c.isImmutable) c.isChecked = true
          })
          this.allChecked = true
        } else {
          this.cates.forEach(c => {
            if (!c.isImmutable) c.isChecked = false
          })
          this.allChecked = false
        }
      }
    },
    // 중요: 일괄합체
    async mergeCate () {
      try {
        // 체크된 갯수가 2개 이상이어야 의미가 있다
        if (this.checkedLength() < 2) throw new Error(`합체할 2개 이상의 카테고리를 선택하시기 바랍니다`)
        if (await this.$refs.mergeCateDialog.open('카테고리', { width: 550 }, this.typeNum, this.selectedCates)) {
          // refresh
          await this.getList()
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 체크된 갯수 리턴
    checkedLength () {
      this.selectedCates = [] // 초기화
      let cnt = 0
      this.cates.forEach(c => {
        if (c.isChecked === true) {
          this.selectedCates.push(c) // 선택한 카테고리 배열에 넣어준다.
          cnt++
        }
      })
      return cnt
    },
    // 선택삭제
    // async remove (cate, index) {
    //   try {
    //     const msg = `삭제시에는 해당 카테고리와 연계된 상위 정보도 동반삭제되므로 신중을 기하십시오.`
    //     if (await this.$refs.cd0923.open('삭제', msg, { color: 'error', width: 380 })) {
    //       this.cates.splice(index, 1)
    //       // 넘어온 cate.id 를 넘겨서 지워주면 된다.
    //       const { data } = await this.$axios.post('admin/cate/deleteCate', { selectedIds: cate.id })
    //       if (!data.success) throw new Error(`오류가 발생하였습니다.: ${data.message}`)
    //       // refresh
    //       await this.getDataFromApi()
    //       this.init()
    //     }
    //     // !! 백엔드 삭제
    //   } catch (e) {
    //     this.sbpop(e)
    //   }
    // },
    // 일괄삭제
    // async allRemove () {
    //   try {
    //     let selectedIds = [] // 선택한 아이디만
    //     for (let i = 0; i < this.cates.length; i++) {
    //       if (this.cates[i].isChecked) {
    //         selectedIds.push(this.cates[i].id)
    //       }
    //     }
    //     if (selectedIds.length <= 0) throw new Error('삭제할 카테고리를 선택하십시오')
    //     const msg = `삭제시에는 해당 카테고리와 연계된 상위 정보도 동반삭제되므로 신중을 기하십시오.`
    //     if (await this.$refs.cd0923.open('삭제', msg, { color: 'error', width: 380 })) {
    //       // 선택한 카테고리를 순회하면서 지워준다
    //       for (let i = 0; i < selectedIds.length; i++) {
    //         // id로 인덱스를 찾고 지운다
    //         const index = this.cates.findIndex(c => c.id === selectedIds[i])
    //         this.cates.splice(index, 1)
    //       }
    //       // selectedIds 배열을 넘겨서 지우면 된다.
    //       const { data } = await this.$axios.post('admin/cate/deleteCate', { selectedIds })
    //       if (!data.success) throw new Error(`오류가 발생하였습니다.: ${data.message}`)
    //       // refresh
    //       await this.getDataFromApi()
    //       this.init()
    //     }
    //     // !! 백엔드 삭제
    //   } catch (e) {
    //     this.sbpop(e)
    //   }
    // },
    // 중요: 기타 -----
    // !! 등록폼으로 스크롤을 내리는 함수
    goToInput () {
      this.$vuetify.goTo('#inpArea', { duration: 200, offset: -100, easing: 'linear' })
      this.$refs.txtAddCate.focus()
    }
  }
}
</script>
